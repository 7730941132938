import { useState, useEffect, useCallback, useMemo } from 'react';
import { AxiosInstance, AxiosResponse } from 'axios';
import qs from 'qs';

import useAxios from './useAxios';
import { FetchErrorType } from '../models/fetchError';
import { FetchOptionsAllType, FetchOptionsType, FetchOptionsWithInitialResponseDataType } from '../models/fetchOptions';

type UseFetchType<T> = {
  responseData: T;
  loading: boolean;
  response: AxiosResponse;
  error: FetchErrorType;
  get: (url?: string, queryParams?: any) => Promise<AxiosResponse<T>>;
  post: (...params: Parameters<any>) => Promise<AxiosResponse<T>>;
  put: (...params: Parameters<any>) => Promise<AxiosResponse<T>>;
  patch: (...params: Parameters<any>) => Promise<AxiosResponse<T>>;
  remove: (...params: Parameters<any>) => Promise<AxiosResponse>;
  createAxiosInstance: () => AxiosInstance;
};

type BuildErrorType = (error: any) => FetchErrorType;

// useFetch signatures
function useFetch<T = any>(options: FetchOptionsType): UseFetchType<T | undefined>;
function useFetch<T = any>(options: FetchOptionsWithInitialResponseDataType<T>): UseFetchType<T>;

// actual implementation
function useFetch<T = any>(options: FetchOptionsAllType<T>): UseFetchType<T | undefined> {
  const defaultErr: FetchErrorType = {};
  const initialResponseData = useMemo(() => {
    return (options as FetchOptionsWithInitialResponseDataType<T>)?.initialResponseData || undefined;
  }, [options]);

  const [response, setResponse] = useState({} as AxiosResponse);
  const [responseData, setResponseData] = useState(initialResponseData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FetchErrorType>(defaultErr);

  const { axiosInstance, loadRequestInterceptor, loadResponseInterceptor, createAxiosInstance } = useAxios();

  const buildRequestParams: any = useCallback(
    (args: any) => {
      if (args.length === 1) {
        if (options.path) {
          return [options.path, args[0]];
        }
        return [args[0]];
      }
      if (args.length === 3) {
        return [args[0], args[1], args[2]];
      }
      if (args.length === 0) {
        return [options.path];
      }
      return [args[0], args[1]];
    },
    [options.path],
  );

  const put = useCallback(
    (...args: any) => {
      const requestParams = buildRequestParams(args);
      return axiosInstance.put(requestParams[0], requestParams[1], requestParams[2] || null);
    },
    [axiosInstance, buildRequestParams],
  );

  const patch = useCallback(
    (...args: any) => {
      const requestParams = buildRequestParams(args);
      return axiosInstance.patch(requestParams[0], requestParams[1], requestParams[2] || null);
    },
    [axiosInstance, buildRequestParams],
  );

  const post = useCallback(
    (...args: any) => {
      const requestParams = buildRequestParams(args);
      return axiosInstance.post(requestParams[0], requestParams[1], requestParams[2] || null);
    },
    [axiosInstance, buildRequestParams],
  );

  const remove = useCallback(
    (...args: any) => {
      return axiosInstance.delete(args[0]);
    },
    [axiosInstance],
  );

  const get = useCallback(
    (url?: string, queryParams?: any) => {
      let finalUrl = url || options.path || '';
      if (queryParams) {
        finalUrl = `${finalUrl}?${qs.stringify(queryParams)}`;
      }

      return axiosInstance.get(finalUrl);
    },
    [axiosInstance, options.path],
  );

  const buildError: BuildErrorType = (givenError: any) => {
    let errorResponse = {
      message: givenError.toJSON
        ? givenError.toJSON().message
        : 'An unexpected error appeared! Please try again later.',
      code: 0,
    };

    if (givenError.response) {
      errorResponse = {
        ...givenError.response.data,
        code: givenError.response.status,
      };
    }
    return errorResponse;
  };

  useEffect(() => {
    loadRequestInterceptor(
      () => {
        if (options.resetResponseDataOnReload) {
          setResponseData(initialResponseData);
        }
        setLoading(true);
      },
      () => {
        // error
      },
    );

    loadResponseInterceptor(
      (interceptedResponse: AxiosResponse) => {
        setError(null);
        setLoading(false);
        setResponse(interceptedResponse);
        if (typeof interceptedResponse.data !== 'string') {
          setResponseData(interceptedResponse.data);
        }
      },
      (interceptedError: any) => {
        setError(buildError(interceptedError));
        setResponseData(initialResponseData);
        setLoading(false);
      },
    );
  }, [
    initialResponseData,
    loadRequestInterceptor,
    loadResponseInterceptor,
    options.resetResponseDataOnReload,
    response.data,
  ]);

  const initRequestOptions = useCallback(async () => {
    if (Object.keys(options).length <= 0) {
      return;
    }
    const method = options.method || 'GET';
    const load = options.load !== undefined ? options.load : false;

    if (load) {
      try {
        setLoading(true);
        setError(null);
        await axiosInstance.request({
          url: options.path,
          data: options.requestData,
          method,
        });
      } catch (optionsError) {
        setLoading(false);
        setError(buildError(optionsError));
      }
    }
  }, [axiosInstance, options]);

  useEffect(() => {
    initRequestOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    responseData,
    loading,
    response,
    error,
    put,
    get,
    post,
    patch,
    remove,
    createAxiosInstance,
  };
}

export default useFetch;
