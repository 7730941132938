import { message } from 'antd';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { useSignMessage, useAccount, useDisconnect } from 'wagmi';
import useAuth from '../hooks/useAuth';
import { useAccessTokenContext } from './AccessToken.context';

type UserContextType = {
  disconnect: () => void;
  signChallenge: () => Promise<void>;
  selectNft: (tokenId: number) => Promise<void>;
};

export const UserContext = createContext<UserContextType>({} as UserContextType);

type Props = { children: ReactNode };

export const UserProvider: React.FC<Props> = ({ children }) => {
  const { getChallenge, verifyChallenge, selectToken } = useAuth();
  const { updateAccessToken, removeAccessToken, initAccessToken } = useAccessTokenContext();
  const { signMessageAsync } = useSignMessage();
  const { address } = useAccount();
  const { disconnect: disconnectWallet } = useDisconnect();

  const signChallenge = useCallback(async () => {
    const challenge = await getChallenge(address as string);
    if (!challenge) {
      return;
    }

    let signature;
    try {
      signature = await signMessageAsync({ message: challenge });
    } catch (e) {
      if (e instanceof Error) message.error(e.message);
    }

    if (!signature) {
      return;
    }

    const accessToken = await verifyChallenge(challenge, signature);
    if (!accessToken) {
      return;
    }

    updateAccessToken(accessToken);
  }, [getChallenge, address, verifyChallenge, updateAccessToken, signMessageAsync]);

  const selectNft = useCallback(
    async (tokenId: number) => {
      const accessToken = await selectToken(tokenId);
      if (!accessToken) {
        return;
      }

      updateAccessToken(accessToken);
    },
    [selectToken, updateAccessToken],
  );

  const disconnect = useCallback(() => {
    disconnectWallet();
    removeAccessToken();
  }, [removeAccessToken, disconnectWallet]);

  useEffect(() => {
    initAccessToken();
  }, [initAccessToken]);

  const value: UserContextType = useMemo(
    () => ({
      disconnect,
      signChallenge,
      selectNft,
    }),
    [disconnect, signChallenge, selectNft],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => {
  return useContext(UserContext);
};
