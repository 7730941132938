import { ReactNode } from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import IconButton from '../Buttons/IconButton';

import s from './Modal.module.scss';

type Props = {
  children?: ReactNode;
  title?: string;
  onClose?: () => void;
};

const Modal: React.FC<Props> = ({ children, title, onClose }) => {
  return (
    <div className={s.root}>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.title}>{title}</div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={s.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
