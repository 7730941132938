import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { BigNumber, Contract, utils } from 'ethers';
import { useProvider, useSendTransaction } from 'wagmi';
import config from '../../config';
import abi from '../abis/minter.abi.json';
import useFetch from './useFetch';

type MinterStats = {
  fee: number;
  tokensLeftCount: number;
  mintCount: number;
};

export type UseMinterType = {
  getStats: () => Promise<MinterStats>;
  mintToken: () => Promise<void>;
  toPayValue: number;
  setToPayValue: Dispatch<SetStateAction<number>>;
  sendTransactionIsLoading: boolean;
  sendTransactionIsSuccess: boolean;
  sendTransactionIsError: boolean;
  sendTransactionError: Error | null;
  mintIsSuccess: boolean;
};

export default function useMinter(): UseMinterType {
  const [toPayValue, setToPayValue] = useState<number>(1);
  const [mintIsSuccess, setMintIsSuccess] = useState<boolean>(false);
  const provider = useProvider({ chainId: config.acceptedChainId });
  const {
    error: sendTransactionError,
    isError: sendTransactionIsError,
    isLoading: sendTransactionIsLoading,
    isSuccess: sendTransactionIsSuccess,
    sendTransactionAsync,
  } = useSendTransaction({
    request: {
      chainId: config.acceptedChainId,
      to: config.contractMinterAddress,
      value: utils.parseEther(toPayValue.toString()),
    },
  });

  const contract = useMemo(() => {
    return new Contract(config.contractMinterAddress, abi, provider);
  }, [provider]);

  const getStats = useCallback(async () => {
    const [fee, tokensLeftCount, mintCount] = await Promise.all<[BigNumber, BigNumber, BigNumber]>([
      contract.fee(),
      contract.maxMintableTokens(),
      contract.tokenClaimed(),
    ]);

    return {
      fee: parseFloat(utils.formatEther(fee)),
      tokensLeftCount: tokensLeftCount.toNumber(),
      mintCount: mintCount.toNumber(),
    };
  }, [contract]);

  const { post: postMintTransaction } = useFetch({
    path: config.apiRoutes.mint,
  });

  const mintToken = useCallback(async () => {
    const transaction = await sendTransactionAsync();

    const result = await postMintTransaction({ txHash: transaction.hash });
    setMintIsSuccess(result.data.success);
  }, [sendTransactionAsync, postMintTransaction]);

  return {
    getStats,
    mintToken,
    toPayValue,
    setToPayValue,
    sendTransactionIsLoading,
    sendTransactionIsSuccess,
    sendTransactionIsError,
    sendTransactionError,
    mintIsSuccess,
  };
}
