import { PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';

type Props = {
  title?: string;
  subTitle?: string;
};

const PageTitle: React.FC<Props> = ({ title, subTitle }) => {
  const navigate = useNavigate();

  return (
    <PageHeader className="site-page-header" onBack={() => navigate(-1)} title={title} subTitle={subTitle ?? ''} />
  );
};

export default PageTitle;
