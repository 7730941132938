import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';

import { useAppContext } from '../../shared/contexts/App.context';
import { useUserContext } from '../../shared/contexts/User.context';
import { excerptAddress } from '../../shared/utilities/blockchain';
import config from '../../config';

import s from './ConnectButton.module.scss';

const ConnectButton: React.FC = () => {
  const { t } = useTranslation();
  const { setDisplayUserAuth } = useAppContext();
  const { disconnect } = useUserContext();
  const { address, isConnected } = useAccount();

  const handleConnectClick = useCallback(() => {
    setDisplayUserAuth(true);
  }, [setDisplayUserAuth]);

  const navigate = useNavigate();

  return (
    <div>
      {!isConnected ? (
        <Button type="primary" onClick={handleConnectClick}>{t`app.connect`}</Button>
      ) : (
        <>
          <Button
            className={s.profileButton}
            ghost
            onClick={() => {
              navigate(config.routes.profile.path, { replace: true });
            }}
          >
            {config.routes.profile.label}
          </Button>
          <Button type="primary" onClick={disconnect}>
            {t`app.disconnect`} {excerptAddress(address ?? '')}
          </Button>
        </>
      )}
    </div>
  );
};

export default ConnectButton;
