import React from 'react';
import { useConnect } from 'wagmi';
import { Button, Alert } from 'antd';

import Modal from '../Modal/Modal';
import s from './WalletOptionsModal.module.scss';
import config from '../../config';

type Props = {
  onClose: () => void;
};

const WalletOptionsModal: React.FC<Props> = ({ onClose }) => {
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect({ chainId: config.acceptedChainId });

  return (
    <Modal title="Connect" onClose={onClose}>
      <div className={s.alerts}>
        {error && <Alert message={error.message} type="error" showIcon />}
        <Alert banner message={`Supported chain: ${config.chains[config.acceptedChainId].name}`} />
      </div>
      {connectors.map((connector) => (
        <div key={connector.id}>
          <Button
            type="primary"
            size="large"
            className={s.button}
            icon={React.createElement(config.connectors[connector.id].icon)}
            disabled={!connector.ready}
            onClick={() => connect({ connector })}
          >
            {connector.name}
            {!connector.ready && ' (unsupported)'}
            {isLoading && connector.id === pendingConnector?.id && ' (connecting)'}
          </Button>
        </div>
      ))}
    </Modal>
  );
};

export default WalletOptionsModal;
