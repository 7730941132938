export const parseJwt = <T = any>(token: string): T | undefined => {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  } catch (e) {
    //
    return undefined;
  }
};

export default parseJwt;
