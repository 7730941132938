import { FunctionComponent } from 'react';
import { Chain, chain } from 'wagmi';
import { ReactComponent as MetamaskIcon } from './assets/icons/metamask.svg';
import { ReactComponent as WalletConnectIcon } from './assets/icons/walletconnect.svg';

export type RouteItem = {
  path: string;
  label: string;
  inNavbar?: boolean;
};

type Connector = {
  icon: FunctionComponent;
};

type ConfigType = {
  backendUrl: string;
  apiRoutes: {
    loginWallet: string;
    verifyChallenge: string;
    setToken: string;
    mint: string;
    blockchainTokens: string;
    products: string;
    productBuy: string;
    productsOwned: string;
  };
  routes: {
    home: RouteItem;
    profile: RouteItem;
    mint: RouteItem;
    community: RouteItem;
  };
  acceptedChainId: number;
  chains: {
    [key: number]: { name: string; type: Chain };
  };
  connectors: { [key: string]: Connector };
  contractMinterAddress: string;
  alchemyKey: string;
  infuraKey: string;
};

export default {
  backendUrl: `${process.env.REACT_APP_BACKEND_URL || 'ADD_STAGING_API_HERE'}/api/v1`,
  routes: {
    home: { path: '/', label: 'Home', inNavbar: true },
    profile: { path: '/profile', label: 'Profile', inNavbar: false },
    mint: { path: '/mint', label: 'Mint', inNavbar: true },
    community: { path: '/community', label: 'Community', inNavbar: true },
  },
  apiRoutes: {
    loginWallet: '/session/login/wallet',
    verifyChallenge: '/session/verify-challenge',
    setToken: '/session/set-token',
    mint: '/session/mint',
    blockchainTokens: '/blockchain/tokens',
    products: '/product',
    productBuy: '/product/buy',
    productsOwned: '/product/owned',
  },
  acceptedChainId: parseInt(process.env.REACT_APP_ACCEPTED_CHAIN_ID || '0', 10) || 80001,
  chains: {
    137: { name: 'Polygon', type: chain.polygon },
    80001: { name: 'Mumbai Testnet', type: chain.polygonMumbai },
    5: { name: 'Goerli Testnet', type: chain.goerli },
  },
  connectors: { metaMask: { icon: MetamaskIcon }, walletConnect: { icon: WalletConnectIcon } },
  contractMinterAddress: process.env.REACT_APP_MINTER_ADDRESS,
  alchemyKey: process.env.REACT_APP_ALCHEMY_KEY,
  infuraKey: process.env.REACT_APP_INFURA_KEY,
} as ConfigType;
