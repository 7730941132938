import { useTranslation } from 'react-i18next';

import s from './Home.module.scss';

const Home: React.FC = () => {
  const { t } = useTranslation();

  return <div className={s.root}>{t`app.name`} - Home</div>;
};

export default Home;
