import { Routes as BrowserRoutes, Route } from 'react-router-dom';

import Home from './components/Home/Home';
import UserProfilePage from './components/UserProfile/UserProfilePage';
import MintPage from './components/Mint/MintPage';
import CommunityPage from './components/Community/CommunityPage';

import config from './config';

const {
  routes: { home, profile, mint, community },
} = config;

const Routes: React.FC = () => {
  return (
    <BrowserRoutes>
      <Route path={home.path} element={<Home />} />
      <Route path={profile.path} element={<UserProfilePage />} />
      <Route path={mint.path} element={<MintPage />} />
      <Route path={community.path} element={<CommunityPage />} />
    </BrowserRoutes>
  );
};

export default Routes;
