import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  getLocalStorageAccessToken,
  removeLocalStorageAccessToken,
  setLocalStorageAccessToken,
} from '../utilities/localStorage';
import { parseJwt } from '../utilities/jwt';
import { AccessTokenPayload } from '../models/accessTokenPayload';
import { getDateByUnixTimeStamp } from '../utilities/date';

type AccessTokenContextType = {
  accessTokenPayload?: AccessTokenPayload;
  accessToken?: string;
  isAccessTokenWithWallet: boolean;
  updateAccessToken: (newValue: string) => void;
  removeAccessToken: () => void;
  initAccessToken: () => void;
};

export const AccessTokenContext = createContext<AccessTokenContextType>({} as AccessTokenContextType);

type Props = { children: ReactNode };

export const AccessTokenProvider: React.FC<Props> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string>();

  const accessTokenPayload = useMemo((): AccessTokenPayload | undefined => {
    if (!accessToken) {
      return undefined;
    }

    return parseJwt<AccessTokenPayload>(accessToken);
  }, [accessToken]);

  const isAccessTokenWithWallet = useMemo(
    () => !!accessTokenPayload?.walletAddress,
    [accessTokenPayload?.walletAddress],
  );

  const updateAccessToken = useCallback((newValue: string) => {
    setAccessToken(newValue);
    setLocalStorageAccessToken(newValue);
  }, []);

  const removeAccessToken = useCallback(() => {
    setAccessToken(undefined);
    removeLocalStorageAccessToken();
  }, []);

  const initAccessToken = useCallback(() => {
    const localStorageAccessToken = getLocalStorageAccessToken();
    if (!localStorageAccessToken) {
      return;
    }

    const payload = parseJwt<AccessTokenPayload>(localStorageAccessToken);
    if (!payload || getDateByUnixTimeStamp(payload.exp).getTime() <= new Date().getTime()) {
      removeLocalStorageAccessToken();
      return;
    }

    setAccessToken(localStorageAccessToken);
  }, []);

  useEffect(() => {
    if (!accessTokenPayload) {
      return undefined;
    }

    const delay = getDateByUnixTimeStamp(accessTokenPayload.exp).getTime() - new Date().getTime();

    const timer = setTimeout(() => {
      removeAccessToken();
    }, delay);

    return () => clearTimeout(timer);
  }, [accessTokenPayload, removeAccessToken]);

  const value: AccessTokenContextType = useMemo(
    () => ({
      accessTokenPayload,
      accessToken,
      isAccessTokenWithWallet,
      updateAccessToken,
      removeAccessToken,
      initAccessToken,
    }),
    [accessTokenPayload, accessToken, isAccessTokenWithWallet, removeAccessToken, updateAccessToken, initAccessToken],
  );

  return <AccessTokenContext.Provider value={value}>{children}</AccessTokenContext.Provider>;
};

export const useAccessTokenContext = (): AccessTokenContextType => {
  return useContext(AccessTokenContext);
};
