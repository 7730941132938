import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, List, message, Card } from 'antd';
import { useAccessTokenContext } from '../../shared/contexts/AccessToken.context';
import config from '../../config';
import useProduct, { Product } from '../../shared/hooks/useProduct';
import useFetch from '../../shared/hooks/useFetch';
import s from './CommunityProducts.module.scss';

const { Title, Paragraph } = Typography;
const { Meta } = Card;

const MESSAGE_BUY_KEY = 'buy';

const CommunityProducts: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getProducts } = useProduct();
  const { post: postProductBuy } = useFetch({
    path: config.apiRoutes.productBuy,
  });
  const [products, setProducts] = useState<Product[]>([]);
  const { accessTokenPayload } = useAccessTokenContext();

  useEffect(() => {
    const fetchProducts = async () => {
      setProducts(await getProducts());
    };

    if (accessTokenPayload && accessTokenPayload.tokenId) {
      fetchProducts();
    }
  }, [getProducts, products.length, accessTokenPayload]);

  const openBuyMessage = useCallback(
    (method: string, timeout: number, cb?: () => void) => {
      message.loading({ content: t`app.loading`, key: MESSAGE_BUY_KEY });
      setTimeout(() => {
        message.success({ content: `${t`app.successBoughtWith`} ${method}!`, key: MESSAGE_BUY_KEY, duration: 2 });
        if (cb) cb();
      }, timeout);
    },
    [t],
  );

  const handleBuy = useCallback(
    async (productId: number, buyMethod: string) => {
      try {
        await postProductBuy({ productId, buyMethod });
        openBuyMessage(buyMethod, 2000, () =>
          setProducts(products.filter((element: Product) => element.id !== productId)),
        );
      } catch (e) {
        if (e instanceof Error) message.error(e.message);
      }
    },
    [openBuyMessage, postProductBuy, products],
  );

  return (
    <>
      <Title level={3}>{t`app.communityPageProductsTitle`}</Title>
      <Paragraph>{t`app.communityPageProductsDescription`}</Paragraph>
      {accessTokenPayload && accessTokenPayload.tokenId !== null ? (
        <List
          className={s.productsList}
          grid={{
            gutter: 30,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 5,
          }}
          dataSource={products}
          renderItem={(item: Product) => (
            <List.Item>
              <Card
                className={s.product}
                actions={[
                  <Button
                    disabled={!item.stock}
                    block
                    type="primary"
                    onClick={() => handleBuy(item.id, 'fiat')}
                  >{t`app.buyWithUsd`}</Button>,
                  <Button
                    disabled={!item.stock}
                    block
                    type="primary"
                    onClick={() => handleBuy(item.id, 'crypto')}
                  >{t`app.buyWithCrypto`}</Button>,
                ]}
              >
                <Meta
                  title={item.name}
                  description={
                    <>
                      <h3>{item.description}</h3>
                      <p>Price: {item.price}</p>
                      <p>Stock: {item.stock}</p>
                    </>
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <Button
          onClick={() => {
            navigate(config.routes.profile.path, { replace: true });
          }}
        >
          {t`app.selectToken`}
        </Button>
      )}
    </>
  );
};

export default CommunityProducts;
