import { useCallback } from 'react';
import { message } from 'antd';
import useFetch from './useFetch';
import config from '../../config';

export type Product = {
  id: number;
  name: string;
  description: string;
  price: number;
  stock: number;
};

type UseProductType = {
  getProducts: () => Promise<Product[]>;
  getOwnedProducts: () => Promise<Product[]>;
};

export default function useProduct(): UseProductType {
  const { get: getProductsList } = useFetch({
    path: config.apiRoutes.products,
  });

  const { get: getOwnedProductsList } = useFetch({
    path: config.apiRoutes.productsOwned,
  });

  const getProducts = useCallback(async () => {
    try {
      const response = await getProductsList();
      return response.data as Product[];
    } catch (e) {
      if (e instanceof Error) message.error(e.message);
      return [];
    }
  }, [getProductsList]);

  const getOwnedProducts = useCallback(async () => {
    try {
      const response = await getOwnedProductsList();
      return response.data as Product[];
    } catch (e) {
      if (e instanceof Error) message.error(e.message);
      return [];
    }
  }, [getOwnedProductsList]);

  return {
    getProducts,
    getOwnedProducts,
  };
}
