import { useEffect, useState } from 'react';
import { Button, List, Typography, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useProduct, { Product } from '../../shared/hooks/useProduct';
import config from '../../config';
import { useAccessTokenContext } from '../../shared/contexts/AccessToken.context';
import s from './OwnedProductsList.module.scss';

const { Paragraph } = Typography;
const { Meta } = Card;

const OwnedProductsList: React.FC = () => {
  const { getOwnedProducts } = useProduct();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [products, setProducts] = useState<Product[]>([]);
  const { accessTokenPayload } = useAccessTokenContext();

  useEffect(() => {
    const fetchOwnedProducts = async () => {
      setProducts(await getOwnedProducts());
    };

    if (accessTokenPayload) {
      fetchOwnedProducts();
    }
  }, [getOwnedProducts, accessTokenPayload]);

  return (
    <div>
      {products.length ? (
        <List
          grid={{
            gutter: 30,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 5,
          }}
          dataSource={products}
          renderItem={(item) => (
            <List.Item>
              <Card className={s.card}>
                <Meta
                  title={item.name}
                  description={
                    <>
                      <h3>{item.description}</h3>
                      <p>Price: {item.price}</p>
                    </>
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <>
          <Paragraph>{t`app.noProductsDescription`}</Paragraph>
          <Button
            onClick={() => {
              navigate(config.routes.community.path, { replace: true });
            }}
          >
            {t`app.communityPageTitle`}
          </Button>
        </>
      )}
    </div>
  );
};

export default OwnedProductsList;
