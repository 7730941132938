import { BrowserRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { WagmiConfig, createClient, configureChains } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { infuraProvider } from 'wagmi/providers/infura';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import Routes from './routes';
import UserAuth from './components/UserAuth/UserAuth';
import { AccessTokenProvider } from './shared/contexts/AccessToken.context';
import { AppProvider } from './shared/contexts/App.context';
import { UserProvider } from './shared/contexts/User.context';
import 'antd/dist/antd.css';
import Navbar from './components/Navbar/Navbar';
import config from './config';

const { Header, Content, Footer } = Layout;

const { chains, provider, webSocketProvider } = configureChains(
  [config.chains[config.acceptedChainId].type],
  [alchemyProvider({ alchemyId: config.alchemyKey }), infuraProvider({ infuraId: config.infuraKey })],
);

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

const App: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AppProvider>
      <WagmiConfig client={client}>
        <AccessTokenProvider>
          <UserProvider>
            <UserAuth />
            <BrowserRouter>
              <Layout className="layout">
                <Header>
                  <Navbar />
                </Header>
                <Content>
                  <div className="site-layout-wrapper">
                    <div className="site-layout-content">
                      <Routes />
                    </div>
                  </div>
                </Content>
                <Footer>
                  {t`app.name`} ©{new Date().getFullYear()}
                </Footer>
              </Layout>
            </BrowserRouter>
          </UserProvider>
        </AccessTokenProvider>
      </WagmiConfig>
    </AppProvider>
  );
};

export default App;
