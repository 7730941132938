import { ReactNode } from 'react';
import cx from 'classnames';

import s from './IconButton.module.scss';

type Props = {
  onClick?: () => void;
  children: ReactNode;
  className?: string;
};

const IconButton: React.FC<Props> = ({ onClick, children, className }) => {
  return (
    <div role="button" className={cx(s.root, className)} onClick={onClick} aria-hidden="true">
      {children}
    </div>
  );
};

export default IconButton;
