import { useCallback } from 'react';
import { useAccount } from 'wagmi';

import { useAppContext } from '../../shared/contexts/App.context';
import WalletOptionsModal from '../WalletOptionsModal/WalletOptionsModal';
import SignChallengeModal from '../SignChallengeModal/SignChallengeModal';

const UserAuth: React.FC = () => {
  const { displayUserAuth, setDisplayUserAuth } = useAppContext();
  const { isConnected } = useAccount();

  const onClose = useCallback(() => setDisplayUserAuth(false), [setDisplayUserAuth]);

  if (!displayUserAuth) {
    return null;
  }

  if (isConnected) {
    return <SignChallengeModal onClose={onClose} />;
  }

  return <WalletOptionsModal onClose={onClose} />;
};

export default UserAuth;
