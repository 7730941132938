import { useCallback } from 'react';
import { message } from 'antd';
import useFetch from './useFetch';
import config from '../../config';

export type AttributeItem = {
  // eslint-disable-next-line camelcase
  display_type?: '' | 'url' | 'date' | 'number' | 'boost_number' | 'boost_percentage';
  // eslint-disable-next-line camelcase
  trait_type: string;
  value: string;
};

export type Metadata = {
  name: string;
  description: string;
  image: string;
  attributes: AttributeItem[];
};

export type Token = {
  id: number;
  metadata: Metadata;
  revealed: boolean;
};

type UseNftType = {
  getOwnedTokens: () => Promise<Token[]>;
};

export default function useNft(): UseNftType {
  const { get: getTokenIds } = useFetch({
    path: config.apiRoutes.blockchainTokens,
  });

  const getOwnedTokens = useCallback(async () => {
    try {
      const response = await getTokenIds();
      return response.data as Token[];
    } catch (e) {
      if (e instanceof Error) message.error(e.message);
      return [];
    }
  }, [getTokenIds]);

  return {
    getOwnedTokens,
  };
}
