import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useUserContext } from '../../shared/contexts/User.context';
import { useAccessTokenContext } from '../../shared/contexts/AccessToken.context';
import { ReactComponent as CheckIcon } from '../../assets/icons/checkmark.svg';
import s from './TokenSelector.module.scss';

type Props = {
  tokenId: number;
};

const TokenSelector: React.FC<Props> = ({ tokenId }) => {
  const { t } = useTranslation();
  const { selectNft } = useUserContext();
  const { accessTokenPayload } = useAccessTokenContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleTokenSelect = useCallback(async () => {
    setIsLoading(true);
    await selectNft(tokenId);
    setIsLoading(false);
  }, [selectNft, tokenId]);

  return accessTokenPayload && accessTokenPayload.tokenId === tokenId ? (
    <Button type="primary" disabled>
      {t`app.selectedToken`}
    </Button>
  ) : (
    <Button
      type="primary"
      onClick={handleTokenSelect}
      loading={isLoading}
      icon={
        <div className="icon-wrapper icon-small">
          <CheckIcon className={s.checkIcon} key={`set-${tokenId}`} />
        </div>
      }
    >
      {t`app.selectToken`}
    </Button>
  );
};

export default TokenSelector;
