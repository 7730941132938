import { useTranslation } from 'react-i18next';
import { Tag, Card, Button } from 'antd';
import { AttributeItem, Token } from '../../shared/hooks/useNft';
import s from './TokenCard.module.scss';
import TokenSelector from './TokenSelector';
import { getViewableIpfsUri } from '../../shared/utilities/blockchain';

type Props = {
  token: Token;
};

const { Meta } = Card;

const TokenCard: React.FC<Props> = ({ token }) => {
  const { t } = useTranslation();

  return (
    <Card
      className={s.card}
      cover={<img alt={`Avatar of ${token.metadata.name}`} src={getViewableIpfsUri(token.metadata.image)} />}
      actions={[
        token.revealed ? (
          <TokenSelector tokenId={token.id} />
        ) : (
          <Button disabled type="dashed">
            {t`app.toBeRevealed`}
          </Button>
        ),
      ]}
    >
      <Meta
        title={`${token.metadata.name || ''} (#${token.id || ''})`}
        description={
          <>
            <p>{token.metadata.description || ''}</p>
            {token.metadata.attributes.map((attribute: AttributeItem) => (
              <div className={s.attribute} key={attribute.trait_type}>
                <Tag>
                  {attribute.trait_type}: {attribute.value}
                </Tag>
              </div>
            ))}
          </>
        }
      />
    </Card>
  );
};

export default TokenCard;
