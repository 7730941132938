import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import { Button, InputNumber, Progress, Tooltip, Typography, Divider, Col, Row, Statistic, Alert } from 'antd';
import useMinter from '../../shared/hooks/useMinter';
import PageTitle from '../PageTitle/PageTitle';
import s from './MintPage.module.scss';
import ConnectButton from '../ConnectButton/ConnectButton';

const { Title, Paragraph } = Typography;

const MintPage: React.FC = () => {
  const { isConnected } = useAccount();
  const { t } = useTranslation();
  const [toMintCount, setToMintCount] = useState<number>(1);
  const [mintPrice, setMintPrice] = useState<number>(0);
  const [totalTokens, setTotalTokens] = useState<number>(0);
  const [mintedTokens, setMintedTokens] = useState<number>(0);
  const [mintedTokensPercentage, setMintedTokensPercentage] = useState<number>(0);
  const {
    getStats,
    mintToken,
    toPayValue,
    setToPayValue,
    sendTransactionIsLoading,
    sendTransactionIsSuccess,
    sendTransactionIsError,
    sendTransactionError,
    mintIsSuccess,
  } = useMinter();

  useEffect(() => {
    const getMintPrice = async () => {
      const { fee, tokensLeftCount, mintCount } = await getStats();
      setMintPrice(fee);

      setMintedTokens(mintCount);
      setTotalTokens(tokensLeftCount + mintCount);
      setMintedTokensPercentage((mintCount / totalTokens) * 100);
      setToPayValue(toMintCount * mintPrice);
    };

    getMintPrice();
  }, [getStats, mintPrice, setToPayValue, toMintCount, totalTokens, mintIsSuccess]);

  const handleMintSubmit = useCallback(async () => {
    await mintToken();
  }, [mintToken]);

  return (
    <>
      <PageTitle title={t`app.mintPageTitle`} subTitle={t`app.mintPageSubtitle`} />
      <Title level={3}>{t`app.mintPageProgressTitle`}</Title>
      <Paragraph>{t`app.mintPageProgressDescription`}</Paragraph>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title="Minted" value={mintedTokens} suffix={`/ ${totalTokens}`} />
        </Col>
        <Col span={12}>
          <Statistic title="Price" value={mintPrice ?? 'N/A'} suffix="ETH" />
        </Col>
      </Row>
      <Tooltip title={`${mintedTokens} minted / ${totalTokens} available`}>
        <Progress percent={mintedTokensPercentage} status="active" />
      </Tooltip>
      <Divider />
      <Title level={3}>{t`app.mintPageMintTitle`}</Title>
      <Paragraph>{t`app.mintPageMintDescription`}</Paragraph>

      {!isConnected ? (
        <div className={s.mintSection}>
          <ConnectButton />
        </div>
      ) : (
        <div className={s.mintSection}>
          <InputNumber
            min={1}
            max={10}
            value={toMintCount}
            onChange={(value: number) => {
              setToMintCount(value);
              setToPayValue(value * mintPrice);
            }}
          />
          <Button type="primary" onClick={handleMintSubmit}>
            {t`app.mintAction`} {`${toMintCount} for ${toPayValue} ETH`}
          </Button>
          {sendTransactionIsLoading && (
            <Alert
              message={t`app.mintCheckWallet`}
              description={t`app.mintCheckWalletDescription`}
              type="info"
              showIcon
            />
          )}
          {sendTransactionIsSuccess && !mintIsSuccess && (
            <Alert message={t`app.mintPending`} description={t`app.mintPendingDescription`} type="info" showIcon />
          )}
          {mintIsSuccess && (
            <Alert
              message={t`app.mintSuccessful`}
              description={t`app.mintSuccessfulDescription`}
              type="success"
              showIcon
            />
          )}
          {sendTransactionIsError && (
            <Alert
              message={t`app.mintError`}
              description={sendTransactionError && sendTransactionError.message}
              type="error"
              showIcon
            />
          )}
        </div>
      )}
    </>
  );
};

export default MintPage;
