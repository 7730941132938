import { useCallback } from 'react';
import { message } from 'antd';
import useFetch from './useFetch';
import config from '../../config';

export type UseAuthType = {
  getChallenge: (walletAddress: string) => Promise<string | undefined>;
  verifyChallenge: (challenge: string, signature: string) => Promise<string | undefined>;
  selectToken: (tokenId: number) => Promise<string | undefined>;
};

export default function useAuth(): UseAuthType {
  const { post: postLoginWithWallet } = useFetch({
    path: config.apiRoutes.loginWallet,
  });
  const { post: postVerifyChallenge } = useFetch({
    path: config.apiRoutes.verifyChallenge,
  });
  const { post: postSelectToken } = useFetch({
    path: config.apiRoutes.setToken,
  });

  const getChallenge = useCallback(
    async (walletAddress: string) => {
      try {
        const response = await postLoginWithWallet({ walletAddress });
        return response.data.challenge as string;
      } catch (e) {
        if (e instanceof Error) message.error(e.message);
        return undefined;
      }
    },
    [postLoginWithWallet],
  );

  const verifyChallenge = useCallback(
    async (challenge: string, signature: string) => {
      try {
        const response = await postVerifyChallenge({ challenge, signature });
        return response.data.accessToken as string;
      } catch (e) {
        if (e instanceof Error) message.error(e.message);
        return undefined;
      }
    },
    [postVerifyChallenge],
  );

  const selectToken = useCallback(
    async (tokenId: number) => {
      try {
        const response = await postSelectToken({ tokenId });
        return response.data.accessToken as string;
      } catch (e) {
        if (e instanceof Error) message.error(e.message);
        return undefined;
      }
    },
    [postSelectToken],
  );

  return {
    getChallenge,
    verifyChallenge,
    selectToken,
  };
}
