import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import ConnectButton from '../ConnectButton/ConnectButton';
import config, { RouteItem } from '../../config';
import s from './Navbar.module.scss';

const { routes } = config;
const navbarItems = Object.values(routes).filter((item: RouteItem) => item.inNavbar);

const Navbar: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={s.logo} />
      <div className={s.profile}>
        <ConnectButton />
      </div>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={['2']}
        items={navbarItems.map((item: RouteItem) => {
          return {
            key: item.label,
            label: item.label,
            onClick: () => {
              navigate(item.path, { replace: true });
            },
          };
        })}
      />
    </>
  );
};

export default Navbar;
