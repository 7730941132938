import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type AppContextType = {
  isWalletInfoModalOpen: boolean;
  setIsWalletInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  displayUserAuth: boolean;
  setDisplayUserAuth: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

type Props = { children: ReactNode };

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [isWalletInfoModalOpen, setIsWalletInfoModalOpen] = useState(true);
  const [displayUserAuth, setDisplayUserAuth] = useState(false);

  const value: AppContextType = useMemo(
    () => ({
      isWalletInfoModalOpen,
      setIsWalletInfoModalOpen,
      displayUserAuth,
      setDisplayUserAuth,
    }),
    [isWalletInfoModalOpen, displayUserAuth],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = (): AppContextType => {
  return useContext(AppContext);
};
