import { useEffect } from 'react';

import Modal from '../Modal/Modal';
import { useUserContext } from '../../shared/contexts/User.context';
import { useAccessTokenContext } from '../../shared/contexts/AccessToken.context';
import usePageVisibility from '../../shared/hooks/usePageVisibility';

type Props = {
  onClose: () => void;
};

const SignChallengeModal: React.FC<Props> = ({ onClose }) => {
  const { signChallenge } = useUserContext();
  const { accessToken, isAccessTokenWithWallet } = useAccessTokenContext();
  const { isVisible } = usePageVisibility();

  useEffect(() => {
    if (isAccessTokenWithWallet || !isVisible) {
      return;
    }
    signChallenge().then(onClose);
  }, [accessToken, isAccessTokenWithWallet, onClose, signChallenge, isVisible]);

  return (
    <Modal title="Sign Challenge" onClose={onClose}>
      <span>Please open your wallet and sign the security challenge</span>
    </Modal>
  );
};

export default SignChallengeModal;
