import { useEffect, useState } from 'react';
import { Button, List, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useNft, { Token } from '../../shared/hooks/useNft';
import TokenCard from './TokenCard';
import config from '../../config';
import { useAccessTokenContext } from '../../shared/contexts/AccessToken.context';

const { Paragraph } = Typography;

const OwnedTokensList: React.FC = () => {
  const { getOwnedTokens } = useNft();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tokens, setTokens] = useState<Token[]>([]);
  const { accessTokenPayload } = useAccessTokenContext();

  useEffect(() => {
    const fetchOwnedTokens = async () => {
      setTokens(await getOwnedTokens());
    };

    if (accessTokenPayload) {
      fetchOwnedTokens();
    }
  }, [getOwnedTokens, accessTokenPayload]);

  return (
    <div>
      {tokens.length ? (
        <List
          grid={{
            gutter: 30,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 5,
          }}
          dataSource={tokens}
          renderItem={(item) => (
            <List.Item>
              <TokenCard token={item} />
            </List.Item>
          )}
        />
      ) : (
        <>
          <Paragraph>{t`app.noNftsDescription`}</Paragraph>
          <Button
            onClick={() => {
              navigate(config.routes.mint.path, { replace: true });
            }}
          >
            {t`app.mintPageTitle`}
          </Button>
        </>
      )}
    </div>
  );
};

export default OwnedTokensList;
