import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import OwnedTokensList from '../Token/OwnedTokensList';
import OwnedProductsList from '../Product/OwnedProductsList';
import PageTitle from '../PageTitle/PageTitle';

const { TabPane } = Tabs;

const UserProfilePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle title={t`app.profilePageTitle`} subTitle={t`app.profilePageSubtitle`} />
      <Tabs defaultActiveKey="1">
        <TabPane tab={t`app.profilePageOwnedTokensList`} key="1">
          <OwnedTokensList />
        </TabPane>
        <TabPane tab={t`app.profilePageOwnedProductsList`} key="2">
          <OwnedProductsList />
        </TabPane>
      </Tabs>
    </>
  );
};

export default UserProfilePage;
