import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';

import PageTitle from '../PageTitle/PageTitle';
import ConnectButton from '../ConnectButton/ConnectButton';
import CommunityProducts from './CommunityProducts';

const CommunityPage: React.FC = () => {
  const { isConnected } = useAccount();
  const { t } = useTranslation();

  return (
    <>
      <PageTitle title={t`app.communityPageTitle`} subTitle={t`app.communityPageSubtitle`} />
      {!isConnected ? <ConnectButton /> : <CommunityProducts />}
    </>
  );
};

export default CommunityPage;
